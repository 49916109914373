.auth {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; */
}

.auth-img .ant-image,
.logo .ant-image {
    width: 100% !important;
}

.auth-img .ant-image-img {
    height: calc(100vh - 0px);
    object-fit: cover;
}

.auth-outer {
    max-width: 520px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.logo {
    text-align: center;
}

.logo .ant-image-img {
    display: block;
    width: 306px;
    margin: 0 auto 30px;
}

.auth-card {
    border: var(--border) 1px solid;
    border-radius: var(--br-10);
    padding: 40px;
}

.auth-card .ant-typography-primary,
.auth-card .ant-typography-secondary,
.auth .ant-typography-secondary {
    text-align: center;
    display: block;
}

.auth-card .ant-typography-primary {
    text-transform: uppercase;
    margin-bottom: 10px;
}

.auth-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.auth-text {
    margin-bottom: 40px;
}

.footer-text {
    margin-top: 30px;
}

.auth-card .ant-btn {
    width: 100%;
}

@media (max-width: 991px) {
    .auth-card {
        padding: 20px;
    }
}

@media (max-width: 767px) {
    .auth {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo .ant-image-img {
        width: 215px;
        margin-bottom: 20px;
    }

    .auth .ant-col-sm-24:first-child {
        display: none;
    }

    .footer-text {
        margin-top: 20px;
    }
}

@media (max-width: 576px) {
    .auth-card {
        padding: 20px;
    }
}