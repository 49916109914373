.analytics {
    margin-top: 30px;
    border: var(--border) 1px solid;
    border-radius: var(--br-10);
    background-color: var(--white);
}

.analytics-card {
    padding: 20px 20px 0px;
}

.anlytics-middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.analytics-value {
    font-size: 28px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 10px;
}

.analytics-meta {
    display: flex;
    align-items: center;
}

.analytics-percent {
    font-size: 11px;
    border-radius: 20px;
    padding: 4px 10px 3px;
}

.analytics-plus .analytics-percent {
    background-color: rgba(var(--green-rgb), 0.4);
    color: var(--green);
}

.analytics-minus .analytics-percent {
    background-color: rgba(var(--primary-rgb), 0.4);
    color: var(--primary);
}

.analytics-days {
    margin-left: 10px;
}

.analytics-chart {
    margin-top: -20px;
}

@media(max-width: 1191px) {
    .analytics {
        margin-top: 24px;
    }
}