@import '~antd/dist/antd.less';

:root {
    --app-bg: #FAFAFB;
    --primary: #FF1A1A;
    --primary-light: #fe4040;
    --primary-rgb: 255, 26, 26;
    --green: #2AB57D;
    --green-light: #32cc8e;
    --green-rgb: 42, 181, 125;
    --dark: #333;
    --border: #E5E9EF;
    --black: #000000;
    --white: #ffffff;
    --br-10: 10px;
    --br-6: 6px;
}

body {
    overflow-x: hidden;
}

ul {
    list-style-type: none;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-10 {
    margin-top: 10px;
}

.mt-30 {
    margin-top: 30px;
}

.p-0 {
    padding: 0px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.opacity {
    opacity: .75;
}

::marker {
    display: none;
}

.w-100 {
    width: 100%;
}

.btn {
    text-align: center;
    color: var(--white);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 6px;
    display: inline-block;
    transition: all .3s;
}

.btn-primary {
    background-color: var(--primary);
}

.b-p-x {
    padding-left: 30px;
    padding-right: 30px;
}

.m-auto {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.ant-input,
.ant-input-password {
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.ant-form-item-explain {
    margin-top: 5px;
    font-size: 12px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.remember-me .ant-form-item-control-input {
    min-height: initial;
}

.ant-form-vertical .ant-form-item-label {
    line-height: 1;
}

.ant-btn {
    text-transform: uppercase;
    line-height: 1.9;
}

textarea.ant-input {
    height: 120px;
    resize: none;
}

.ant-dropdown-menu-title-content {
    position: relative;
    top: 1px;
}

.ant-layout-content.container {
    transition: all .3s;
    padding: 20px;
}

.rounded-box {
    position: relative;
    border: var(--border) 1px solid;
    border-radius: var(--br-10);
    padding: 20px;
    background-color: var(--white);
}

.table-view.rounded-box {
    padding: 0px;
}

.table-view .ant-typography {
    margin: 20px;
}

.table-view img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
}

.ant-table-thead>tr>th {
    border-top: var(--border) 1px solid;
}

.ant-table-tbody>tr>td {
    padding-top: 8px;
    padding-bottom: 8px;
}

.ant-table-block {
    margin-top: 20px;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.ant-table-pagination.ant-pagination {
    padding-left: 20px;
    padding-right: 20px;
}

.ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 0;
}

.ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 0;
}

.form-btn {
    display: flex;
    // justify-content: flex-end;
}

.ant-input.upload-img {
    padding-top: 9.5px;
    padding-bottom: 9.5px;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-modal-close {
    top: -40px;
    right: -45px;
    color: var(--white);
}

.ant-modal-close:hover {
    color: var(--white);
}

// Create
.create {
    display: flex;
    justify-content: flex-end;
}

.img-border img {
    border-radius: 100%;
}

.horizontal-img .ant-image {
    width: auto !important;
}

.horizontal-img img {
    width: 60px;
    height: 40px;
    border-radius: 6px;
    object-fit: cover;
}

.ant-switch-checked {
    background: var(--green);
}

.table-btns {
    display: flex;
    align-items: center;
}

.table-btn a {
    width: 30px;
    height: 30px;
    line-height: 35px;
    color: var(--white);
    display: block;
    text-align: center;
    border-radius: 30px;
    transition: all .3s;
}

.delete-btn {
    margin-left: 15px;
}

.edit-btn a {
    background-color: var(--green);
}

.edit-btn a:hover {
    background-color: var(--green-light);
}

.delete-btn a {
    background-color: var(--primary);
}

.delete-btn a:hover {
    background-color: var(--primary-light);
}

.upload-image {
    margin-bottom: 30px;
    display: block;
}

.upload-image .ant-btn {
    border: none;
    white-space: unset;
    height: 60px;
    display: inline-flex;

}

.form-btn-group {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
}

.form-btn-group .form-btn:first-child {
    margin-right: 15px;
}

.ambassadors-table .ant-table-thead>tr>th:nth-child(9),
.ambassadors-table .ant-table-tbody>tr>td:nth-child(9) {
    width: 20%;
}

.blog-table .ant-table-thead>tr>th:nth-child(7),
.blog-table .ant-table-tbody>tr>td:nth-child(7),
.blog-table .ant-table-thead>tr>th:nth-child(8),
.blog-table .ant-table-tbody>tr>td:nth-child(8) {
    width: 20%;
}

.ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 20px;
}

.ant-upload-list-picture-card-container {
    width: 100%;
    height: 250px;
    margin: 0;
}

.hero-post-card {
    border: var(--border) 1px solid;
    border-radius: var(--br-10);
    padding: 10px;
    margin-top: 25px;
}

.home-hero h5.ant-typography {
    margin-bottom: 0;
}

.hero-post-card .ant-upload-list-picture-card .ant-upload-list-item {
    border: none;
    padding: 0;
}

.hero-img .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.hero-img .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
    border-radius: 6px;
}

.hero-post-card .ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
    margin-top: 10px;
    width: 100%;
    height: 44px;
    background-color: var(--primary);
    border: none;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
}

.hero-post-card .ant-upload.ant-upload-select-picture-card:hover {
    border: none;
}

.hero-post-card .ant-upload.ant-upload-select-picture-card>.ant-upload {
    color: var(--white);
}

.hero-post-card textarea.ant-input {
    height: 100px;
}

.about-form .ant-form-item {
    margin-bottom: 15px;
}

.rdw-editor-main {
    border: var(--border) 1px solid;
    border-radius: var(--br-10);
    padding-left: 20px;
    padding-right: 20px;
    height: 500px;
}

@media(max-width: 767px) {
    .create .ant-btn {
        width: 100%;
    }

    .rounded-box h4.ant-typography {
        font-size: 16px;
    }

    .hero-post-card {
        margin-top: 0px;
    }

    .about-form .ant-form-item {
        margin-bottom: 0;
    }
}

@media(max-width: 576px) {
    .rounded-box h4.ant-typography {
        font-size: 14px;
    }
}

.ant-image-img :hover{
    border: 2px solid red;
    cursor: pointer;
}
@body-background: #FAFAFB;@layout-body-background: #FAFAFB;@layout-header-background: #ffffff;@layout-sider-background: #000000;@primary-color: #FF1A1A;@danger-color: #FF1A1A;@border-radius-base: 10px;@font-family: 'Rubik', sans-serif;@heading-color: #333333;@text-color: #333333;@text-color-secondary: #343A40;@border-color-base: #E5E9EF;@heading-2-size: 26px;@heading-3-size: 22px;@heading-4-size: 18px;@heading-5-size: 16px;@typography-title-font-weight: 500;@input-height-base: 49px;@input-placeholder-color: #959796;@input-border-color: #E5E9EF;@input-bg: #ffffff;@checkbox-size: 20px;@btn-font-weight: 500;@btn-border-radius-base: 6px;@btn-height-base: 44px;@btn-shadow: none;@menu-dark-color: #ffffff;@menu-dark-bg: #000000;@menu-dark-item-active-bg: #FF1A1A;@menu-dark-selected-item-icon-color: #ffffff;@menu-dark-selected-item-text-color: #ffffff;@page-header-back-color: #ffffff;@dropdown-vertical-padding: 10px;@table-header-bg: #F4F6F7;@table-header-color: #2C302E;@table-header-sort-bg: #F4F6F7;@table-padding-vertical: 14px;@table-padding-horizontal: 20px;@table-border-color: #E5E9EF;