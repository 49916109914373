.menu-logo {
    padding: 21px 20px;
    cursor: pointer;
}

.ant-layout-sider {
    transition: all .2s;
}

.sider-sider {
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
}

.main-content {
    padding-left: 250px;
}

.hide-sider,
.sider-close {
    transform: translateX(-100%);
    flex: 0 0 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;
    overflow: hidden;
}

.main-content-close {
    padding-left: 0px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
    padding-left: 20px !important;
}

.left-menu .ant-menu-item:first-child {
    margin-top: 0;
}

.left-menu .ant-menu-item:nth-child(2) img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.left-menu .ant-menu-item.ant-menu-item-selected:nth-child(2) img {
    -webkit-filter: initial;
    filter: initial;
}

.left-menu .ant-menu-title-content {
    position: relative;
    top: 1px;
}

.ant-layout-header.ant-header {
    position: sticky;
    top: 0;
    left: 250px;
    right: 250px;
    width: 100%;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    z-index: 3;
    justify-content: space-between;
    line-height: 45px;
    border-bottom: var(--border) 1px solid;
}

.ant-header-left {
    display: flex;
}

.black-logo {
    display: none;
    cursor: pointer;
}

.main-content-close .black-logo {
    display: block;
    margin-right: 20px;
    cursor: pointer;
}

.ant-header-arrow {
    line-height: initial;
    padding: 15px 10px 10px 10px;
    cursor: pointer;
}

.ant-header-arrow svg {
    color: var(--dark);
}

.main-content {
    height: 100%;
}

.header-end {
    display: flex;
    align-items: center;
}

.header-end .ant-avatar-circle {
    border: var(--border) 1px solid;
}

.header-end .ant-dropdown-trigger {
    color: var(--dark);
}

.ant-layout-footer {
    text-align: center;
}

@media(max-width: 991px) {
    .main-content {
        padding-left: 0;
    }

    .ant-layout-sider-zero-width-trigger {
        top: 15px !important;
        right: -50px !important;
        background-color: var(--black) !important;
        width: 35px !important;
        height: 35px !important;
        line-height: 36px !important;
        border-radius: 6px !important;
    }

    .ant-header-arrow {
        display: none;
    }

    .header-end {
        margin-left: auto;
    }
}