.fd-tab .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    background-color: #ff1a1a;
}

.fd-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: white;
}

.fs-dn{
    display: none;
}

.fs-spin {
    height: calc(100vh - 505px);
    display: flex;
    align-items: center;
    justify-content: center;
}


.ml-2{
margin-left: 5px;
}