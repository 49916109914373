.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-breadcrumb li:last-child {
    opacity: .75;
}

@media(max-width: 767px) {
    .header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .header .ant-typography {
        margin-bottom: 10px !important;
    }
}