.bar-chart {
    margin-top: 15px;
}

/* @media(max-width: 576px) {
    .bar-chart {
        min-height: 250px !important;
    }

    .bar-chart .apexcharts-canvas,
    .bar-chart .apexcharts-canvas svg {
        height: 250px !important;
    }
} */